

.mercari-form {
  label {
    //background-color: red;
    min-height: 14px;
    min-width: 5px;
  }

  .package-details {
    label {
      width: 200%;
    }
  }

  .mercary-brands {
    cursor: pointer;
    color: #2f665c;
  }
  
}
