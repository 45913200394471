.title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.36px;
  color: #495057;
}

.description {
  font-family: Poppins;
  font-size: 14px;
  line-height: 1.71;
  color: #495057;
}
