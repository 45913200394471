.inputWrapper {
  width: 100%;
  max-width: 59px;
  height: 36px;
  padding: 9px 12px 8px;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: #fff;
}
