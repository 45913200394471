.product-status {
  display: flex;
  align-items: center;
  padding: 6px 18px;
  border-radius: 3.2px;
  font-size: 12px;
  color: #74818d;
  margin-left: 20px;
}

.is-posted {
  background-color: #eff6f2;
}

.not-posted {
  background-color: #f5f5f5;
}
