.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 2px;
}

.mainInfo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 8px;
}

.mainPriceInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 8px;
}

.warning {
  background-color: rgba(241, 180, 76, 0.1);
  min-width: 668px;
  padding: 12px 20px 10px 20px;

  span {
    display: block;
    font-family: Poppins;
    font-size: 13px;
    color: #f1b44c;
  }
}

.bottomWarning {
  font-family: Poppins;
  font-size: 10px;
  color: #a1aab2;
  max-width: 530px;
}

.dropdownWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 365px;
  margin-bottom: 16px;

  label {
    margin-bottom: 0;
  }

  & > div {
    width: 220px;
  }
}

.emptyPriceSoldWarning {
  display: block;
  font-family: Poppins;
  font-size: 10px;
  color: #a1aab2;
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
