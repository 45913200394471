.loader {
  float: right;
}

.loader.centered {
  position: absolute;
  padding: 0;
  top: 46%;
}

.spinner {
  text-align: center;
  margin-left: 16px;
}

.spinner > div {
  width: 8px;
  height: 8px;
  margin-right: 3px;
  background-color: #2f665c;
  vertical-align: middle;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}


@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}
