.analytics {
  &__wrapper {
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
  }
  &__item {
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__sum {
    color: #2f665c;
    font-size: 19px;
    font-weight: 600;
  }
}