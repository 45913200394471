.wrapper {
  display: flex;
  align-items: flex-end;
  margin-top: 25px;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  min-height: 38px;
  min-width: 80px;

  label {
    font-family: Poppins;
    font-size: 14px;
    color: #2e2e2e;
  }
}
