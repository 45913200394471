.draft-images-collection {
  display: flex;
  flex-wrap: wrap;

  .draft-container-image{
    margin-right: 25px;
    margin-bottom: 25px;
  }

  .draft-wrapper-image {
    max-width: 200px;
    max-height: 200px;
    width: 200px;
    height: 200px;
    cursor: grab;
  }

  &:last-child {
    margin-right: auto;
  }

  .drop-zone-draft-card {
    width: 200px;

    .dropzone-file,
    .upload-container {
      max-height: 200px;
    }
  }
}


