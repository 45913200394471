.buttonWrapper {
  display: flex;
  justify-content: center;
  min-width: 200px;
  min-height: 200px;
  border: 2px dashed #cfd3d7;
  background: #ffffff;
  border-radius: 6px;
  font-size: 2.3rem;
  cursor: pointer;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.27;
  color: #495057;
  margin-bottom: 0;
}
