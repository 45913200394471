.subTitle {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #74818d;
}

.fontWeight600 {
  font-weight: 600;
}

.fontWeight500 {
  font-weight: 500;
}
