.account-pages {
  margin-top: 15vh;
}

.auth-header {
  position: relative;
  background-color: #2F665C;
  color: #FFFFFF;
  margin-bottom: 75px;

  h5 {
    font-size: .9375rem;
    color: #FFFFFF;
    font-weight: 600;
  }

  .auth-logo-light {
    position: absolute;
    left: 28px;
  }
}

.social-body {
  .google-button {
    border: 1px solid #2F665C;
    border-radius: 4px;
    background-color: #fff;
    color: #74818D;

    &:focus, &:active, &:hover, &:disabled {
      background-color: #fff;
      border: 1px solid #2F665C;
      color: #74818D;
    }

    span {
      margin-left: .5rem;
      font-size: .8125rem;
    }
  }
}

.devide-line {
  position: relative;
  margin: 25px 0px;

  p {
    display: inline-block;
    color: #74818D;
    font-size: .8125rem;
    z-index: 2;
    background-color: #fff;
    padding: 0 13px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  hr {
    background-color: #F6F6F6;
  }
}

.custom-form-group {
  position: relative;
  color: #495057;
  font-size: .8125rem;

  label {
    font-weight: 500;
  }

  .input-password-wrapper {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    input {
      border: none;
    }

    .view-password {
      display: flex;
      align-items: center;
      margin: 0 .5rem;
      cursor: pointer;
    }
  }

  .is-invalid-input {
    border-color: #f46a6a;
    background-image: none !important;
  }
}

.btn.register-submit {
  font-size: .8125rem;
  color: #fff;
  border-radius: 18px;
  background-color: #2F665C;
  padding-left: 1.75rem;
  padding-right: 1.75rem;

  &:hover {
    color: #fff;
  }
}

.sign-up-footer, .privacy-content {
  a {
    color: #2F665C;
    font-weight: 600;

    &:hover {
      color: #2F665C;
    }
  }
}

.custom-green-checkbox {
  input {
    &:checked {
      background-color: #2F665C;
      border-color: #2F665C;
    }

    &:focus {
      box-shadow: 0 0 0 0.15 rgba(47, 102, 92, .25);
    }
  }    
}

h2 {
  &.ui-bold-black {
    font-size: 1.25rem;
    color: #495057;
    font-weight: 600;
  }
}

.ui-regular-black {
  font-size: .8125rem;
  color: #495057;
}

.ui-regular-gray {
  font-size: .8125rem;
  color: #74818d;
}

.ui-icon-md {
  font-size: 1.3rem;
}

[type="button"].resend-button {
  color: #fff;
  border-radius: 18px;
  background-color: #2F665C;
  padding: 8px 22px;  

  &:focus, &:active, &:hover, &:disabled {
    color: #fff;
    border-radius: 18px;
    background-color: #2F665C;
  }

  &_md {
    padding: 8px 40px;
  }

  &_lg {
    padding: 8px 54px;  
  }
}

.modal-content {
  .modal-header {
    border-bottom: 1px solid #f6f6f6;
  }
  
  .modal-footer {
    border-top: 1px solid #f6f6f6;
    button {
      min-width: 127px;
    }
  }
}

.custom-expired-card {
  padding: 2rem;
  margin-top: 15vh;

  p {
    margin: 0 2rem 1.5rem;
  }
}

.account-pages {
  .card {
    border-radius: .5rem ;
    margin-bottom: 2.5rem;
  }
}

.default-secondary-alert {
  background-color: #eff6f2;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    padding: .7rem;
  }
}

.forgot-password-link:hover, .forgot-password-link:focus, {
 color: #2F665C;
}