
.draft-caption-text {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #495057;
  margin-top: 24px;

  .green {
    font-weight: 600;
    color: #2f665c
  }
}
