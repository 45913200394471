.faqListItemWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}

.titleWrapper {
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #495057;
}

.description {
  display: block;
  font-family: Poppins;
  font-size: 13px;
  color: #74818d;
  margin-bottom: 30px;
}

.divider {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #e2e2e2;
}
