.avatar-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #f7f7f7;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;

  &:hover .overlay {
    opacity: 0.5;
  }
}

.avatar-uploader {
  display: block !important;
  opacity: 0;
  border-radius: 50%;
  position: absolute;
  width: 150px;
  height: 150px;
  cursor: pointer;
  z-index: 3;
}

.avatar-preview {
  display: block;
  height: auto;
  width: 100%;
  border-radius: 50%;
  z-index: 1;
}

.overlay {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 2;
}
