.text-gray-gomi {
  color: #74818d;
}

.comment {
  &__bottom-line {
    border-bottom: 1px solid #f6f6f6;
  }
  &__button.btn {
    height: 27px;
    padding: .3rem 1rem;
  }
}

.fw-medium {
  font-weight: 500;
}
