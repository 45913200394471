.wrapper {
  margin-left: 13px;
}

.title {
  display: block;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  color: #495057;
}

.subtitle {
  display: block;
  font-family: Poppins;
  font-size: 13px;
  color: #74818d;
}
