.dateInputContainer {
  min-width: 165px;

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      position: relative;
      min-width: 165px;
    }

    input {
      width: 167px;
      height: 28px;
      padding: 5px 12px;
      border-radius: 3.2px;
      border: solid 1px #ced4da;
      background-color: #fff;
      outline: none;
      font-family: Poppins;
      font-size: 12px;
      color: #495057;
    }

    .react-datepicker__close-icon::after {
      width: 12px;
      height: 12px;
      font-size: 25px;
      color: #2f665c;
      background: none;
    }
  }

  .react-datepicker__triangle {
    left: -60px !important;
  }

  .react-datepicker__triangle::before {
    border-bottom-color: #97b2ad !important;
  }

  .react-datepicker__triangle::after {
    border-bottom-color: #eff6f2 !important;
  }

  .react-datepicker__header {
    & > * {
      color: #495057;
    }

    & > * > * {
      color: #495057;
    }

    background-color: #eff6f2;
    border-bottom: 1px solid #97b2ad;
  }

  .react-datepicker__navigation {
    top: 7px;
  }

  .react-datepicker__navigation-icon--previous::before {
    border: 5px solid transparent;
    border-left: 5px solid #97b2ad;
    transform: rotate(180deg);
  }

  .react-datepicker__navigation-icon--next::before {
    border: 5px solid transparent;
    border-left: 5px solid #97b2ad;
    transform: none;
  }

  .react-datepicker {
    border: 1px solid #97b2ad;
  }

  .react-datepicker__month {
    color: #343a40;
  }

  .react-datepicker__day {
    font-family: Poppins;
    font-size: 13px;
    color: #343a40;

    &:hover {
      border-radius: 13.5px;
    }
  }

  .react-datepicker__day--keyboard-selected {
    font-family: Poppins;
    font-size: 13px;
    color: #343a40;
    background: none;

    &:hover {
      border-radius: 13.5px;
    }
  }

  .react-datepicker__day--today {
    color: #343a40;
    background: none;
    font-weight: bold;
  }

  .react-datepicker__day--in-range {
    border-radius: 13.5px;
    background-color: #2f665c;
    color: #eff2f7;
    font-weight: unset;
  }

  .react-datepicker__day--selected {
    border-radius: 13.5px;
    background-color: #2f665c;
    color: #fff;
  }
}
