.chart {
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }
  &__marker {
    display: flex;
    align-items: flex-start;
    position: absolute;
    &:nth-child(1) {
      left: 0;
      top: 60px;
      .marker {
        background-color: #b7d8d5;
      }
    }
    &:nth-child(2) {
      left: 0;
      bottom: 75px;
      .marker {
        background-color: #82b1ac;
      }
    }
    &:nth-child(3) {
      right: 0;
      top: 60px;
      .marker {
        background-color: #2f665c;
      }
    }
    &:nth-child(4) {
      right: 0;
      bottom: 75px;
      .marker {
        background-color: #4f8c83;
      }
    }
    &:nth-child(5) {
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
      .marker {
        background-color: #5eaca1;
      }
    }
  }
}
.marker {
  width: 13px;
  height: 13px;
  background-color: #2f665c;
  border-radius: 50%;
  margin-right: 6px;
  margin-top: 5px;
  &__title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__sum {
    font-size: 13px;
    color: #2f665c;
  }
}