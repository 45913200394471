.drafts-list-toolbar {
  margin-bottom: 24px;
  margin-top: 4px;
}

.sold-list-toolbar{
  margin-bottom: 24px;
  margin-top: 4px;
  @media (max-width: 1300px){
    display: flex;
    flex-wrap: wrap;
  }
}

.sold-header-counters {
  margin-left: auto;

  @media (max-width: 1300px){
    width: 100%;
    margin: 0 auto;
    padding-right: 16px;
    justify-content: space-between;
  }

  &__add {
    margin-left: 26px;
  }
}

.inventory-list-toolbar {
  margin-bottom: 24px;
  margin-top: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1418px){
    display: flex;
    flex-wrap: wrap;
  }
}

.navigate-sold-inventory-container{
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1300px){
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }
}

.navigate-inventory-container{
  width: 100%;
  justify-content: space-between;
  margin-right: 16px;
  
  @media (max-width: 1418px){
    margin-bottom: 20px;
  }
}

.merge-button{
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: 26px;
  i{
    padding-left: 4px;
    line-height: 1;
  }
}

.inventory-import-button {
  width: 100px;
}