.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 365px;
  min-height: 232px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
  margin: 0 25px 25px 0;
  padding: 20px 20px 16px 20px;
  object-fit: contain;
}

.statusTitle {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  padding: 1px 10px 3px;
  margin-left: auto;
  object-fit: contain;
  border-radius: 160px;
}

.activeStatus {
  color: #2f665c;
  background-color: #e7f6ee;
}

.notActiveStatus {
  color: #74818d;
  background-color: #f5f5f5;
}

.titleWithImageWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: none;
  border: none;
  margin-right: 16px;
}

.name {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #343a40;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.reconnectDisconnectButtons {
  & :nth-child(1) {
    width: 155px;
    height: 36px;
    padding: 9px 16px 8px;
    object-fit: contain;
    border-radius: 18px;
    border: none;
    background-color: #2f665c;
    font-family: Poppins;
    font-size: 13px;
    text-align: center;
    color: #ffffff;
  }

  & :nth-child(2) {
    width: 155px;
    height: 36px;
    padding: 8px 16px 9px;
    object-fit: contain;
    border-radius: 18px;
    border: 1px solid #ee786c;
    background: none;
    font-family: Poppins;
    font-size: 13px;
    text-align: center;
    color: #ee786c;
  }

  & :nth-child(3) {
    display: none;
  }

  & :nth-child(3)[disabled] {
    opacity: 0.5;
  }
}

.connectButton {
  & :nth-child(1) {
    display: none;
  }

  & :nth-child(2) {
    display: none;
  }

  & :nth-child(3) {
    width: 100%;
    height: 36px;
    padding: 9px 16px 8px;
    object-fit: contain;
    border-radius: 18px;
    border: none;
    background-color: #2f665c;
    font-family: Poppins;
    font-size: 13px;
    text-align: center;
    color: #ffffff;
  }

  & :nth-child(3)[disabled] {
    opacity: 0.5;
  }
}

.activeStatusBadge {
  position: absolute;
  right: 20px;
  top: 20px;
}
