.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.min-height-spinner-section {
  min-height: 500px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.cursor-pointer {
  cursor: pointer;
}



.tooltip[x-placement="top"] {
  margin-bottom: 8px;

  .arrow{
    background:transparent;
    z-index: 99;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #495057;
    bottom: -5px;
  }
}

.scrollbar-container {
  height: 200px;
  // div div:nth-child(3) {
  //   div {
  //     background-color: red !important;
  //   }
  // }
}

.is-invalid-input.react-select-wrap,
.is-invalid.react-select-wrap {
  > div {
    border-color: #f46a6a;
  }
}

.is-invalid.btn {
  border-color: #f46a6a;
}

.select-breadcrumb {
  padding: .75rem 1.5rem;

  span {
    cursor: pointer;
  }
}

.highlighted-text {
  font-weight: inherit;
  background-color: rgba(47,102,92,0.2);
}

.custom-input-group-addon {
  span {
    background-color: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.form-control.custom-input-addon + .custom-input-group-addon.input-group-append {
  span {
    border-left: 1px solid transparent;
  }
}

.custom-form-group {
  .custom-input-addon {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.spinner-border {
  .sr-only {
    display: none;
  }
}

.dropdown-search-input {
  width: 100%;
  div {
    width: 100%;
    label {
      width: 100%;
    }
  }
}

.checkbox-container-upc {
  height: 36px;
  margin-top: 3px;
  display: flex;
  align-items: center;
}

.active.breadcrumb-item {
  & > a {
    font-weight: 600;
  }
}

.card > hr {
  margin: 0;
}

.custom-marketplace-card {
  height: calc(100% - 24px);
}

.marketplace-body-text {
  margin-bottom: 0;
}

.cost-secondary-alert {
  background-color: #eff6f2;
  padding: 1.125rem 1.5rem;
  margin-bottom: 2.5rem;
  border: solid 1px #97b2ad;
  border-radius: 4px;
}

.custorm-form-group__input-wrapper {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: .58rem .75rem;
}

.custom-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f46a6a;
}

.dropdown {
  .dropdown-item {
    padding: 12px 16px;
  }

  .custom-button-dropdown {
    font-size: .75rem;
    background-color: #f5f5f5;
    color: #495057;
    padding: .3rem 1rem;

    &:hover, &:active, &:focus {
      background-color: #f5f5f5;
      color: #495057;
    }
  }

  .dropdown-item.active, .dropdown-item:active {
    font-weight: 600;
    background-color: #fff;
  }
}

.green-color {
  color: $primary-gomi;
}

.dropdown {
  .dropdown-divider {
    border-top-color: #eff2f7;
    margin: 0;
  }
}

.card-import-container-empty {
  position: relative;
  min-height: 630px;
  max-height: 630px;
  margin-bottom: 2rem;
}


.custom-green-scroll {
  &::-webkit-scrollbar {
    width: .3125rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2f665c;
    border-radius: 5px;
  }
}

.card-import-container {
  position: relative;
  min-height: 630px;
  max-height: 630px;
//  overflow-y: scroll;
  margin-bottom: 2rem;

  &__no-matches {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.card-import-item {
  display: flex;
  align-items: center;
  padding: 1.125rem 0;
  justify-content: space-between;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;

  &__checkbox {
    margin: 0 1rem;
  }

  &__right {

    min-width: 200px;
    width: 330px;
    display: flex;
    justify-content: space-around;

    &-imported {
      min-width: 74px;
    }
  }
}

.sortable {
  cursor: pointer;
}

.opacity-5 {
  opacity: .5;
}

.card {
  .return-card-header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #f6f6f6;
  }
}

.custom-return {
  margin-bottom: 1.2rem;
  &__label {
    min-width: 90px;
    margin-right: .5rem;
  }
}

.returned-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.expenses-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding-top: 25vh !important;
  padding-bottom: 25vh !important;
}

.expenses-placeholder-img{
  width: 107px;
  height: 102px;
}

.subscription-setting-card-outer {
  padding: 2.5rem;
}

.custom-input-group-payment {
  .form-control:disabled {
    background-color: inherit;
    opacity: inherit;
  }
}


.spinner-border.text-primary {
  color: var(--bs-primary) !important;
}