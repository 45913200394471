.ellipsis {
  max-width: 370px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imageWrapper {
  display: block;
  position: relative;
  height: 100%;
}

#bundleCounter {
  position: absolute;
  right: -20px;
  top: -10px;
  margin-right: 0;
}

@media (max-width: 1200px) {
  .ellipsis {
    max-width: 320px;
  }
}
