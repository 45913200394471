.expense-category-item-wrapper {
  display: flex;
  align-items: center;
  padding: 13px 16px;
  border-bottom: 1px solid #eff2f7;
  font-family: Poppins;
  font-size: 13px;
  color: #495057;
  cursor: pointer;
}
