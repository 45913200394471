.expense-title {
    display: flex;
    align-items: center;

    p {
        margin-left: 16px;
        margin-bottom: 0;
        overflow: hidden;
        max-width: 16vw;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
}

