@import "../../common.module.scss";

@media (max-width: 1024px) {
  .pickedTitle {
    display: none;
  }
}

@media (max-width: 767px) {
  .pickingHeader {
    display: none;
  }
}
