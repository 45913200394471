.info-wrapper {
  display: flex;
  justify-content: space-between;
}

.main-info {
  display: flex;
  align-items: center;
}

.avatar-image {
  width: 72px;
  height: 72px;
  margin-right: 19px;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.name {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #495057;
  margin-bottom: 4px;
}

.email {
  font-family: Poppins;
  font-size: 13px;
  color: #74818d;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
