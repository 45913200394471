.wrapper {
  display: flex;
  flex-direction: column;
}

.tabs-wrapper {
  border-radius: 4px;
  //position: relative;
  z-index: 10;
  background-color: #ffffff;

  .nav-item {
    margin: 2px;
    min-width: 160px;
    text-align: center;

    a {
      min-width: inherit;
    }
  }
}

.nav-link {
  cursor: pointer;

  &:hover {
    color: #2f665c;
  }
}

.target-link{
  &:hover,
  &:focus,
  &:active {
    color: #2f665c;
    text-decoration: underline !important;
  }
}

.nav-pills .nav-link.active {
  color: #2f665c;
  background-color: #eff6f2;
}

.tabs-children {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

@media (max-width: 767px) {
  .tabs-wrapper {
    .nav-item {
      min-width: 140px;
    }
  }
}
