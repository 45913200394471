.filter-sorting-dropdown-menu {
  padding: 8px 0 11px;

  &__menu {
    width: 200px;
    padding: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }

  &__menu::-webkit-scrollbar {
    display: none;
  }

  &__name {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.filter-sorting-dropdown-wrapper {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 28px;
  padding: 0;
  border: none;
  background-color: transparent;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  color: #495057;
  text-align: left;
  margin: 0;
}

@media (max-width: 767px) {
  .filter-sorting-dropdown-wrapper {
    width: 145px;
  }
}

.filter-sorting-dropdown-icon {
  margin-bottom: 8px;
  color: hsl(0, 0%, 80%);
  width: 8px;
  height: 5px;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  margin-left: 3px;
}

.filter-sorting-dropdown-item-wrapper {
  display: flex;
  align-items: center;
  padding: 13px 16px;
  border-bottom: 1px solid #eff2f7;
  font-family: Poppins;
  font-size: 13px;
  color: #495057;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }
}
