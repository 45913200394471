.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 95px;
  background-color: #fff;
  border: 1px solid #97b2ad;
  border-left: none;
  border-radius: 4px;
  padding: 12px;

  & > div {
    margin-bottom: 12px;
  }
}
