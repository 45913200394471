.checkbox {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;

  & > input {
    display: block;
    margin: auto 0;
    // width: 16px;
    // height: 16px;
    margin-right: 10px;
  }

  & > label {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #495057;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 26px;
}

.dropdown {
  & > label {
    display: block;
    width: fit-content;
    margin-bottom: 9px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    color: #495057;
  }

  & > div {
    max-width: 232px;
    width: 100%;
  }
}

.daysDropdown {
  margin-top: 2px;
  margin-bottom: 24px;
}

.returnsShippingDropdown {
  margin-bottom: 20px;
}
