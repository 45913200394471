.listingUrlInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 100%;

  & button {
    margin-left: 16px;
    border: none;
    background: none;
  }
}

.formikInputContainer {
  display: block;
  width: 100%;
}

.input {
  width: 100%;
}

.copyBtn {
  margin-top: 5px;
}

.disabledCopyBtn {
  opacity: 0.5;
}

.copySvg {
  cursor: pointer;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.labelCopied {
  width: 124px;
  height: 45px;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid  #97b2ad;
  background-color: #eff6f2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: auto;
  right: 0;
  top: 38px;
  opacity:0;
  span {
    color: #2f665c;
  }
}

.labelIsCopiedShow {
  animation: show 2s 1;
  animation-fill-mode: forwards;
}




