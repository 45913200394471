.dropdownItem {
  display: flex;
  align-items: center;
  padding: 13px 0 13px 16px;
  border-bottom: 1px solid #eff2f7;
  font-family: Poppins;
  font-size: 13px;

  &:hover {
    background-color: #f5f5f5;
  }
}

.disabledItem {
  cursor: initial;
  color: gray;
}

.activeItem {
  color: #495057;
  cursor: pointer;
}