.searchWrapper {
  display: flex;
  align-items: center;
  width: 100%;

  .search-box{
    margin-right: 0 !important;
  }
}

.title{
  font-size: 13px;
  font-weight: 600;
  color: #495057;
}
