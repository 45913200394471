.wrapper {
  display: block;
  position: relative;
  width: 100%;
  margin-top: 32px;
  white-space: nowrap;
}

.topPart {
  display: flex;
  min-width: 100%;
  width: fit-content;
  justify-content: space-between;
  min-height: 71px;
  background-color: #eff6f2;
  border-radius: 4px;
  padding: 24px;

  .title {
    display: block;
    min-width: 200px;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 1.21;
    color: #2f665c;
    margin-right: auto;
  }

  .intervalPeriod {
    display: block;
    min-width: 210px;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: bold;
    text-align: right;
    color: #495057;
  }

  .total {
    display: block;
    min-width: 210px;
    font-family: Helvetica;
    font-size: 13px;
    font-weight: bold;
    text-align: right;
    color: #495057;
  }
}

.mainPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  width: fit-content;
  min-height: 59px;
  padding: 0 24px;
  background-color: #fff;
  border-bottom: 1px solid #f6f6f6;

  .itemTitle {
    display: block;
    min-width: 200px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    color: #495057;
    margin-right: auto;
  }

  .itemValue {
    display: block;
    min-width: 210px;
    font-family: Poppins;
    font-size: 15px;
    line-height: 1.27;
    text-align: right;
    color: #495057;
  }
}

.bottomPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  width: fit-content;
  min-height: 63px;
  padding: 0 24px;
  background-color: #fff;
  border-top: 1px solid #97b2ad;
  border-bottom: 1px solid #97b2ad;

  .totalTitle {
    display: block;
    min-width: 200px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    color: #2f665c;
    margin-right: auto;
  }

  .totalValue {
    display: block;
    min-width: 210px;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 1.21;
    text-align: right;
    color: #2f665c;
  }
}
