.tableTitle {
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.27;
  color: #495057;
}

.headerCounters {
  display: flex;
  align-items: center;
  margin-left: auto;

  button {
    margin-left: 26px;
  }
}
