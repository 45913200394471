body .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #343a40;
}

body.modal-open  {
  padding-right: 0px!important;
}