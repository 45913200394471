
.container{
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 400px;
}



.chartColumn{
  min-width: 480px;
}

.overviewColumn{
  padding-right: 20px;
  width: 63%;
}

@media (min-width: 978px) and (max-width: 1080px) {
  .container {
    margin-left: -70px;
  }
  .overviewColumn{
    margin-left: -60px;
  }
}