.shippingList {
  &Item {
    padding: 23px 24px 23px 20px;
    background: #fff;
    border-radius: 4px;

    & + & {
      margin-top: 8px;
    }
  }

  &Header {
    min-height: unset;
    padding: 12px 24px 12px 20px;
    background-color: #eff6f2;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
    border: solid 1px #cededb;
    margin: 0 0 12px;
  }

  &Cell {
    min-height: 36px;
  }
}

.page {
  min-height: 580px;
}

.chip {
  padding: 1px 9px 3px;
  border-radius: 160px;
  background-color: #f8efe6;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
}

.tabChildrenWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 16px;
}

.filterTitle {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  color: #495057;
  margin-right: 8px;
}

@media (max-width: 767px) {
  .tabChildrenWrapper {
    display: none;
  }

  .shippingList {
    &Item {
      padding: 20px 20px 23px 20px !important;
    }
  }
}
