


.select-btn {
  box-sizing: border-box;
  height: 36px;
  width: 100%;
  border: 1px solid #CED4DA;
  border-radius: 4px;
  background-color: #FFFFFF;

  .empty-state {
    margin-bottom: 0;
    color: #a1aab3
  }

  .filled-state {
  //  overflow: hidden;
    margin-bottom: 0;
    direction: rtl;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.focus {
    border: 1px solid #2F665C;
  }

  color: #495057;
  font-size: 13px;
  padding: 0 0 0 12px ;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
}

.dropdown-select-wrap-list {
  padding: 16px;

  .head {
    color: #A1AAB3;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 24px;
    line-height: 17px;
    display: flex;
  }

  .table-search {
    margin-right: 0!important;
  }
  svg {
    margin-left: auto;
  }
}
