.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  color: #495057;
}

.dateRange {
  font-family: Poppins;
  font-size: 14px;
  text-align: right;
  color: #74788d;
}
