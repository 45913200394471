.size-item {
  width: 100px;
  height: 36px;
  border-radius: 10px;
  border: solid 1px #e7eaed;
  background-color: #fff;
  .size-name {
    color: #495057; //$dark-gomi
  }
}

.mr-3.mb-3.cursor-pointer.d-flex.align-items-center.justify-content-center.size-item.active {
  background-color: #2f665c; //$primary-gomi
  border-color: #2f665c;
  .size-name {
    color: white;
  }
}

.size-item:hover {
  background-color: #eff6f2; //$secondary-gomi;
  border-color: #eff6f2;
}