.searchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  label {
    width: 245px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .searchWrapper {
    label {
      width: 210px;
    }
  }
}