.brandOption {
  display: inline-block;
  width: fit-content;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #2f665c;
  text-decoration: underline;
  cursor: pointer;
}
