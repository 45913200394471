.filter-dropdown-menu {
  padding: 8px 0 11px;

  &__menu {
    width: 200px;

    padding: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }

  &__menu::-webkit-scrollbar {
    display: none;
  }

  &__name {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.filter-dropdown-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 180px;
  height: 28px;
  padding: 6px 12px 6px 12px;
  object-fit: contain;
  border: solid 1px #cfd3d7;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
  background-color: #fff;
  font-family: Poppins;
  font-size: 13px;
  color: #495057;
  text-align: left;
}

@media (max-width: 767px) {
  .filter-dropdown-wrapper {
    width: 145px;
  }
}

.filter-dropdown-icon {
  color: hsl(0, 0%, 80%);
  width: 11px;
  height: 7px;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

.filter-dropdown-item-wrapper {
  display: flex;
  align-items: center;
  padding: 13px 16px;
  border-bottom: 1px solid #eff2f7;
  font-family: Poppins;
  font-size: 13px;
  color: #495057;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }
}
