.drafts-product-preview {
  height: 45px;
  width: 45px;
  border-radius: 10px;
  background-color: whitesmoke;
  object-fit: contain;
}

.react-viewer-inline > .react-viewer-mask {
  background-color: white;
}

.react-viewer.react-viewer-transition.react-viewer-inline {
  .react-viewer-footer {
    ul.react-viewer-toolbar {
      height: 68px;
      padding: 16px;
      margin: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      li.react-viewer-btn:first-child {
        border-radius: 4px 0 0 4px;
      }

      li.react-viewer-btn:last-child,
      li.react-viewer-btn[data-key="zoomOut"] {
        border-radius: 0 4px 4px 0;
      }

      li.react-viewer-btn {
        width: 160px;
        height: 36px;
        border-radius: 0;
        margin-right: 0;
        cursor: pointer;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2f665c;
      }

      li:hover {
        background-color: #97b2ad;
      }

      li.react-viewer-btn[data-key="reset"] {
        display: none;
      }
    }
  }
}
