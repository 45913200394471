.button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
}

.dateRange {
  font-family: Poppins;
  font-size: 15px;
  line-height: 1.27;
  color: #2f665c;
  margin-right: 8px;
}
