.label {
  display: block;
  margin-bottom: 17px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.27;
  text-align: left;
  color: #495057;
}

.note {
  display: block;
  max-width: 540px;
  font-family: Poppins;
  font-size: 13px;
  text-align: left;
  color: #495057;
}
