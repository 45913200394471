.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.changeCardLabel {
  position: absolute;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #2f665c;
  right: 0;
  z-index: 100;
  &:hover{
    color: #2f665c;
  }
}

.secondaryText {
  color: #a1a9b2;
  margin-bottom: 4px;
  font-size: 13px;
}
