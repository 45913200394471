.count-entries {
  color: #74818d;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;

  .count-entries-badge {
    height: 20px;
    min-width: 25px;
    padding: 0 6px;
    border-radius: 160px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;

    &-green {
      background-color: #2f665c;
    }
    &-gray {
      background-color: #495057;
    }
    &-brown {
      background-color: #dbaf82;
    }
  }
}
