.dropdown-menu {
  padding: 0;
}

.navbar-header .dropdown-menu.show {
  top: 55px !important;
  left: -36px !important;
}

.draft-form-header {
  min-height: 30px;

  .dropdown-menu.show {
    top: 55px !important;
    left: -36px !important;
  }
}

.arrow-down-svg {
  margin-left: 13px;
}
