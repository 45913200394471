.ellipsis {
  max-width: 370px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper {
  display: flex;
  min-height: 140px;
  background: #ffffff;
  width: 97%;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 12px;
  border: 2px solid #ffffff;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: auto;
}

.wrapperActive {
  display: flex;
  min-height: 140px;
  background: #ffffff;
  width: 97%;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 12px;
  border: 2px solid #2f665c;
  cursor: pointer;
}

.image {
  width: 102px;
  height: 124px;
  border-radius: 4px;
  border: 1px solid #000;
  margin-right: 15px;
  object-fit: contain;
}

.contentContainer {
  width: calc(100% - 120px);
}
.title {
  font-size: 13px;
  font-weight: 600;
  color: #495057;
  margin-top: 12px;
  margin-bottom: 16px;
}
.container {
  display: flex;
}

.marketplaceContainer {
  p {
    margin-bottom: 0;
    margin-right: 8px;
    padding: 0 10px;
    border-radius: 10px;
    background-color: #f8efe6;
    display: inline-block;
  }
}

.box {
  width: 35%;
  span {
    display: inline-block;
    width: 65%;
  }
  p {
    padding: 0 10px;
    border-radius: 10px;
    background-color: #f8efe6;
    display: inline-block;
  }
  .main {
    vertical-align: top;
    width: 35%;
    font-size: 13px;
    font-weight: 600;
    color: #495057;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .box {
    width: 50%;
    display: flex;
    justify-content: space-between;
    span {
      display: inline-block;
      width: 60%;
    }
  }
  .marketplaceContainer {
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 2px;
      display: block;
    }
  }
}
