@import "../../common.module.scss";

.editAndSaveBtn {
  margin-left: 11px;
  margin-bottom: 2px;
}

@media (min-width: 1024px) {
  .rowItem {
    span {
      display: none;
    }
  }

  .rowItemTitle {
    display: none;
  }

  .tabletItemWrapper {
    display: flex;
  }

  .tabletContainer {
    display: none;
  }

  .mobileContainer {
    display: none;
  }
}

@media (max-width: 1024px) {
  #rowItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    text-align: flex-start;
    width: 130px;
  }

  .rowItemTitle {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    color: #495057;
    margin-bottom: 8px;
  }

  .tabletItemWrapper {
    display: flex;
    flex-direction: column;
  }

  .desktopContainer {
    display: none;
  }

  .tabletContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .tabletColumnRowsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 116px;
  }

  .tabletColumnRow {
    display: flex;
    flex-direction: column;
  }

  .tabletChip {
    padding: 1px 9px 3px;
    border-radius: 160px;
    background-color: #f8efe6;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    width: fit-content;
  }

  .mobileContainer {
    display: none;
  }
}

@media (max-width: 767px) {
  .tabletContainer {
    display: none;
  }

  .mobileContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;

    .rowItemTitle {
      margin: 0;
    }

    .mobileColumnRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .mobileTitle {
      display: block;
      width: 90%;
      font-family: Poppins;
      font-size: 13px;
      font-weight: 600;
      text-align: left;
      color: #343a40;
      margin-bottom: 4px;
    }

    .mobileCheckbox {
      position: absolute;
      top: 0;
      right: 0;

      input {
        margin: 0;
      }
    }

    .mobileChip {
      padding: 1px 9px 3px;
      border-radius: 160px;
      background-color: #f8efe6;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      width: fit-content;
    }
  }
}
