%default-primary {
  font-size: .8125rem;
  border-radius: 18px;
  padding: .4375rem 1rem;
}

%filled-header {
  min-width: 130px;
  padding: .9375rem 1rem;
  font-weight: 500;
  font-size: 1rem;    
  border-radius: 27px;
  line-height: 1.5;
}

%filled-md {
  padding: .625rem 1rem;
  font-weight: 500;
  border-radius: 23px;
  line-height: 1.5;
}

%filled-sm {
  min-width: 90px;
  font-size: .6875rem;
  padding: .275rem .5625rem;
  border-radius: 13.5px;
}

%filled-xsm {
  min-width: 60px;
  font-size: 11px;
  padding: 0;
  border-radius: 3px;
  height: 27px;
}

button {
  &.filled-primary {
    @extend %default-primary;
    min-width: 127px;
    color: $white;
    background-color: $primary-gomi;

    &:hover {
      color: $white;
      background-color: $landing-primary-text;      
    }
  }

  &.outline-primary {
    @extend %default-primary;
    min-width: 80px;
    color: $primary-gomi;
    background-color: $white;    
    border: 1px solid $primary-gomi;
    i {
      transition: color ease-in .15s;
    }

    &:hover {
      color: $white;
      background-color: $primary-gomi;

      i {
        color: $white;
      }
    }
  }

  &.outline-danger {
    @extend %default-primary;
    min-width: 80px;    
    color: $danger-gomi;
    background-color: $white;    
    border: 1px solid $danger-gomi;

    &:hover {
      color: $white;
      background-color: $danger-gomi;
    }
  }

  &.filled-primary-lg {
    font-size: 1rem;
    min-width: 134px;
    color: $white;
    border-radius: 21px;
    background-color: $primary-gomi; 
    
    &:hover {
      color: $white;
      background-color: $landing-primary-text;
    }
  }

  &.filler-primary-header {
    @extend %filled-header;
    background-color: $primary-gomi;
    color: $white;

    &:hover {
      color: $white;
      background-color: $landing-primary-text;
    }
  }

  &.filler-white-header {
    @extend %filled-header;
    background-color: $white;
    color: $primary-gomi;

    &:hover {
      color: $primary-gomi;
    }
  }

  &.filled-primary-md {
    @extend %filled-md;
    min-width: 110px;
    background-color: $primary-gomi;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &.filled-white-md {
    @extend %filled-md;
    min-width: 110px;
    background-color: $white;
    color: $primary-gomi;
    border: 2px solid $primary-gomi;

    &:hover {
      color: $primary-gomi;
    }
  }

  &.filled-sm {
    @extend %filled-sm;
    background-color: $primary-gomi;
    color: $white;

    &:hover {
      background-color: $landing-primary-text;
      color: $white;
    }
  }

  &.outline-primary-sm {
    @extend %filled-sm;
    background-color: $white;
    color: $primary-gomi;
    border: 1px solid $primary-gomi;

    &:hover {
      color: $white;
      background-color: $primary-gomi;
    }
  }

  &.filled-xsm {
    @extend %filled-xsm;
    background-color: $primary-gomi;
    color: $white;

    &:hover {
      background-color: $landing-primary-text;
      color: $white;
    }
  }

  &.outline-primary-xsm {
    @extend %filled-xsm;
    background-color: $white;
    color: $primary-gomi;
    border: 1px solid $primary-gomi;

    &:hover {
      color: $white;
      background-color: $primary-gomi;
    }
  }

  &.outline-primary-danger {
    @extend %filled-sm;
    background-color: $white;
    color: $danger-gomi;
    border: 1px solid $danger-gomi;

    &:hover {
      color: $white;
      background-color: $danger-gomi;
    }
  }

  &:disabled {    
    opacity: .5;    
  }

  &.w-130 {
    min-width: 128px;
  }

  &.w-80 {
    min-width: 80px;
  }

  &.w-180 {
    min-width: 180px;
  }
}