.drafts-empty-list {
  height: 850px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    margin-bottom: 28px !important;
  }

  p {
    max-width: 320px;
    color: #74818d;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    margin-bottom: 35px;
  }
}
