.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #495057;
}

.label {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #495057;
}
