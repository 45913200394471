.modalBodyWrapper {
  & > div {
    min-height: 80vh !important;
  }
}

.halfContainer{
  position: relative;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 16px 16px 4px;
}
.infinitLoader{
  position: absolute;
  width: 100%;
  bottom: -7px;
  right: 4px;
}

.left{
  margin-right: 16px;
}

.cardMergeContainerEmpty {
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 630px;
  max-height: 630px;
  margin-bottom: 2rem;
}

.cardMergeContainer{
  position: relative;
  min-height: 630px;
  max-height: 630px;
  overflow-y: scroll;
  margin-bottom: 2rem;

  &::-webkit-scrollbar {
    width: .3125rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 3px;
  }

  &__no-matches {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}