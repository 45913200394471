.overview-container {
  padding: 30px;
}

.item-container:not(:last-child) {
  border-bottom: 1px solid #eff2f7 !important;
}

.item-label{
  padding-left: 8px;
}

.item-value {
  padding-right: 8px;
}