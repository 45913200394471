.edit-avatar-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto 40px 10px;

  .button {
    min-width: 122px;
  }
}

.avatar-wrapper {
  display: flex;
  margin-right: 24px;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  z-index: 3;
}
