.uploadImageWrapper {
  display: flex;
  justify-content: center;
  width: 156px;
  height: 156px;
  border: 2px dashed #cfd3d7;
  background: #ffffff;
  border-radius: 6px;
  font-size: 2.3rem;
  cursor: pointer;
}

.imageError {
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 80%;
  color: #f46a6a;
}
