.cookie-toaster {
  width: auto;
  max-width: 1200px;
  min-width: 320px;

  .Toastify__toast {
    cursor: auto;
  }
}

.toastify-cookie {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 2rem;
  z-index: 999;

  &-container {
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 0 15px 0 lightgray;

    h3 {
      font-size: 1.375rem;
      font-weight: 700;
    }

    .ml-2 {
      margin-left: 0.5rem;
    }

    &__info {
      display: flex;

      p {
        font-size: 1.125rem;
        margin-right: 1rem;
      }

      &-link {
        display: flex;
        align-items: center;
      }
    }
  }
}
