.createNewOptionWrapper {
  display: flex;
  align-items: center;
  padding-left: 9px;
  padding-right: 9px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #eff6f2;
  margin-bottom: 12px;

  span {
    display: flex;
    align-items: center;
    padding: 9px 14px 8px 9px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    color: #495057;
    cursor: pointer;

    i {
      font-size: 24px;
    }
  }
}
