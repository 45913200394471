.row {
  display: flex;
  justify-content: space-between;
}

.block {
  text-align: center;
  min-height: 128px;
  margin-right: 14px;
  padding: 20px 27px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  border: solid 1px #ced4da;

  &:last-child {
    margin-right: 0;
  }



  .title {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.27;
  }

  .desc {
    font-size: 13px;
  }

  &:not(.active) {
    background-color: #fff;

    .title {
      color: #495057;
    }

    .desc {
      color: #a1aab3;
    }
  }

  &.active {
    color: white;
    background-color: #2f665c;

    .title, .desc {
      color: inherit;
    }
  }
}
.invalidConditionMessage{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f46a6a;
}
