.container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 47px;
}


.itemContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  margin-bottom: 25px;
  &:first-child .mark{
    background-color: #2f665c;
  }
  &:nth-child(2) .mark{
    background-color: #82b1ac;
  }
  &:nth-child(3) .mark{
    background-color: #4f8c83;
  }
  &:nth-child(4) .mark{
    background-color: #b7d8d5;
  }
  &:last-child .mark{
    background-color: #5eaca1;
  }
}
.mark{
  margin-right: 16px;
  width: 13px;
  height: 13px;
  background-color: #2f665c;
  border-radius: 50%;
}

.infoContainer{
  width: 112px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text{
  font-family: Poppins, serif;
  font-size: 16px;
}

.price{
  font-family: Poppins;
  font-size: 19px;
  font-weight: 600;
  color: #2f665c;
}

@media (min-width: 730px) and (max-width: 1350px) {
  .container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .itemContainer {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;

  }
}