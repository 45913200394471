.uploaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  i {
    font-size: 60px;
    margin-bottom: 8px;
  }

  p {
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    color: #495057;
  }
}

.selectedImageWrapper {
  width: 156px;
  height: 156px;
}
