.Toastify__toast--success {
  background-color: #eff6f2;
  border-radius: 3px;
  border: solid 1px #97b2ad;

  .Toastify__toast-body {
    font-size: 14px;
    font-weight: normal;
    color: #495057;
  }

  .Toastify__close-button > svg {
    fill: #2f665c;
  }
}

