.task-list {
  padding: 0;
  list-style: none;
  cursor: pointer;
  &__item {
    margin-bottom: 20px;
    border-radius: 4px;
    border: solid 1px #cededb;
    padding: 0.75rem 1.25rem;
    color: #495057;
    background-color: #fff;
    min-height: 80px;
    &_secondary {
      background-color: #eff6f2;
      border-color: #eff6f2;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.list {
  &-heading {
    font-weight: 600;
    margin-bottom: 10px;
  }
  &-text {
    &_bold {
      font-weight: 600;
      color: #2f665c;
    }
  }
  &-info {
    display: flex;
    align-items: center;
  }
  &-icon {
    width: 41px;
    height: 41px;
    margin-right: 25px;
    border-radius: 50%;
    border: 2px solid #2f665c;
    color: #2f665c;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-part {
    font-size: 16px;
    font-weight: 500;
  }
  &-numb {
    font-size: 19px;
    font-weight: 600;
  }
}
