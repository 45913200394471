
.fee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  margin-top: 8px;

   p {
    font-weight: 500;
    color: #74818d;
    margin-bottom: 0;
  }

  &.bold p {
    color: #495057
  }
}
