.custom-badges {
  // display: inline-block;
  font-size: .75rem;
  padding: 1px 9px;
  border-radius: 1rem;
  font-weight: 500;

  &_green {
    background-color: #e7f6ee;
    color: #2f665c;
  }

  &_grey {
    background-color: #f5f5f5;
    color: #74818d;
  }

  &_yellow {
    background-color: #f8efe6;
    color: #2e2e2e;
  }
}