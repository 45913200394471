.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px dashed #cfd3d7;
  &:hover .overlay {
    opacity: 0.5;
    border-radius: 6px;
    z-index: 0;
  }

  &:hover .editButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  &:hover .deleteButton {
    display: block;
    cursor: pointer;
    z-index: 4;
  }
}

.disabledImage {
  filter: grayscale(100%);
  opacity: 0.5;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.deleteButton {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffffff;
  padding: 0;
}

.editButtonWrapper {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.editButton {
  background: none;
  color: #ffffff;
  padding: 5px 23px 5px 17px;
  object-fit: contain;
  border-radius: 13.5px;
  border: 1px solid #ffffff;
}

.imageLoader {
  position: absolute;
  display: none;

  > div {
    margin-left: 0;

    > div {
      background-color: #cfd3d7;
    }
  }
}

.image {
  width: 100%;
  height: 200px;
  object-fit: contain;

  &.loading {
    + .imageLoader {
      display: block;
    }
  }
}

