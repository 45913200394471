

.block {
  background: #f5f5f5;
  padding: 20px 20px 24px 20px;

}

.p {
  color: #74818d;
  font-size: 13px;
  margin-top: 4px;
  margin-bottom: 0;
}
.btn {
  padding:4px 12px 4px 12px;
  border-radius: 3.2px;
  border: solid 1px #495057;
  font-family: Helvetica;
  font-size: 11.4px;
  color: #495057;
  margin-left: auto;
  margin-bottom: auto;

  &:hover {
    background-color: #495057;
    color: white;
  }
}
