.progressBar {
  max-width: 300px;
  width: auto;
  min-height: 60px;
  height: auto;
  position: fixed;
  top: 75px;
  padding: 14px 21px;
  border-radius: 610px;
  box-shadow: 0 10px 20px 0 rgba(47, 102, 92, 0.08);
  border: solid 1px #ededed;
  background-color: #fff;
  z-index: 12;
  cursor: move;
}

.positionProgressbar {
  right: 30px;
}

.offsetPositionProgressBar {
  right: 310px;
}