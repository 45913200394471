.searchWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.searchButton{
  border: none;
  width: 68px;
  font-size: 11px;
  padding-bottom: 0;
  padding-top: 0;
}
