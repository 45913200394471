.contact-attached-file {
  display: flex;
  align-items: center;
  margin-right: 1rem;

  p {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  i {
    font-size: 1.2rem;
    cursor: pointer;
  }
}
