.title-color {
  color: #59857d;
}

.title-text-color {
  color: #2f665c;
}

.title-wrapper {
  background-color: #eff6f2;
  border-radius: 4px;
}

.top-layer {
  z-index: 9;
}

.moth-goal-field {
  z-index: 9;
  .form-control {
    color: #2f665c;
    font-size: 22px;
    font-weight: 600;
    padding: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    z-index: 9;
  }
}

.analytics-chart-container{
  padding-bottom: 10px;
}