.container{
  padding: 0 81px 0 0;
  margin: 53px 0 0;

}

.listItem{
  display: flex;
  align-items: center;
  margin-bottom: 46px;

}
.inventoryListIndex{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: #eff6f2;
  color: #2f665c;
  font-family: 'Poppins', serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 50%;
}
.inventoryListName{
  font-family: Poppins, serif;
  font-size: 16px;
  font-weight: 600;
  color: #495057;
  overflow: hidden;
  width: 33%;
  padding-left: 16px;
  padding-right: 16px;
}

.listContainerText{
  width: calc(67% - 36px);
  overflow: hidden;
}

.listText{
  display: flex;
  align-items: center;
  font-family: Poppins, serif;
  font-size: 15px;
  padding-left: 22px;
  padding-right: 22px;
  font-weight: 600;
  color: #2f665c;
  height: 35px;
  background-color: #eff6f2;
  width: 100%;
}
