.wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 200px;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 180px;
}

.title {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  color: #495057;
}

.value {
  font-family: Poppins;
  font-size: 13px;
  color: #74818d;
}
