.wrapper-tabs-size {
  .nav-pills > li > a {
    color: #495057;
    font-weight: 500;
  }

  .nav-pills .nav-link .active {
    color: #2f665c;
    background-color: #eff6f2;
  }

  .tabs-wrapper .nav-item {
    margin: 2px;
    min-width: auto;
    text-align: center;
  }

  .nav-link:hover {
    color: #2f665c;
  }
}
