.table-placeholder {
  height: pxToRem(350);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;

  &__title {
    color: #74788d;
    font-size: pxToRem(16);
    font-weight: 600;
    margin-bottom: pxToRem(38);
  }

  &__image {
    height: xToRem(172);
    width: pxToRem(176);
  }
}

.react-bs-table-no-data:hover {
  background-color: $white;
  box-shadow: none;
}

.priceInputWrapper {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
