.card {
  padding: 36px 0 40px;

  h3 {
    margin-bottom: 12px;
  }

  p {
    max-width: 216px;
  }
}