.size-container {
  position: relative;
  box-sizing: border-box;
  &.size-disabled {
    pointer-events: none;
    .size-control .size-ValueContainer .size-singleValue {
      color: #a1aab3;
    }
  }
  .size-control {
    align-items: center;
    background-color: transparent;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    color: #495057;
    .size-ValueContainer {
      align-items: center;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      padding: 2px 8px;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      height: 31px;
    }
    .size-placeholder {
      color: rgba(73,80,87,0.2);
      margin-left: 2px;
      margin-right: 2px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
    }
    .size-singleValue {
      color: hsl(0,0%,20%);
      margin-left: 2px;
      margin-right: 2px;
      max-width: calc(100% - 8px);
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      white-space: nowrap;
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
    }
    .size-IndicatorsContainer {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-shrink: 0;
      box-sizing: border-box;
    }
  }
  .size-menu {
    top: 100%;
    background-color: hsl(0,0%,100%);
    border-radius: 4px;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.15);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    min-width: 715px;
    z-index: 1;
    box-sizing: border-box;
  }
}

.size-control:hover {
  border-color: #2F665C;
}

.size-divider {
  height: 1px;
  width: 100%;
  background-color: #F6F6F6;
  margin-top: 7px;
  margin-bottom: 24px;
}