.filter-block {
  &__menu {
    width: 200px;
    height: 235px;
    padding: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }

  &__menu::-webkit-scrollbar {
    display: none;
  }

  &__name {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

//.filter-dropdown-wrapper + .dropdown-menu.show {
////  top: 30px !important;
//  right: 0!important;
//  left: auto;
//}

.to-right {
  right: 0;
  left: auto !important;
}


.expense-category-filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  height: 28px;
  padding: 6px 12px 6px 12px;
  object-fit: contain;
  border: solid 1px #cfd3d7;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
  background-color: #fff;
  font-family: Poppins;
  font-size: 13px;
  color: #495057;
  text-align: left;
}

.expense-category-filter-icon {
  color: hsl(0, 0%, 80%);
  width: 11px;
  height: 7px;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}
