@import "../../common.module.scss";

@media (max-width: 767px) {
  .desktopContainer {
    display: none;
  }

  .mobileContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;

    .mobileTitle {
      display: block;
      width: 90%;
      font-family: Poppins;
      font-size: 13px;
      font-weight: 600;
      text-align: left;
      color: #495057;
      margin-bottom: 4px;
    }

    .mobileRowWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: Poppins;
      font-size: 13px;
      font-weight: 600;
      text-align: left;
      color: #495057;
    }

    .rowItemTitle {
      font-family: Poppins;
      font-size: 13px;
      font-weight: 600;
      text-align: left;
      color: #495057;
    }

    .mobileCheckbox {
      position: absolute;
      top: 0;
      right: 0;

      input {
        margin: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .mobileContainer {
    display: none;
  }
}
