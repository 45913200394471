.container {
  display: flex;
  flex-direction: column;
  max-width: 725px;
  width: 100%;

  span {
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.57;
    color: #74818d;
    margin-bottom: 12px;
  }
}

.title {
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  color: #495057;
  margin-bottom: 32px;
  margin-top: 30px;
}
