.button-wrapper {
  margin-top: 80px;
  .custom-size-btn {
    width: 90px;
  }
}

.custom-size-save {
  color: #2f665c;
}

p.mb-0.fw-bold.cursor-pointer.custom-size-save.disabled {
  color: #4b545c;
  pointer-events: none;
}

.custom-size-save:hover {
  color: #1f7556;
}

.input-block {
  width: 617px;
}

.save-block {
  width: 33px;
}

.action-block {
  width: 90px;
}