.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 165px;
  width: 100%;
  min-height: 38px;
  padding: 6px 12px 6px 12px;
  object-fit: contain;
  border: solid 1px #cfd3d7;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.03);
  background-color: #fff;
  font-family: Poppins;
  font-size: 13px;
  color: #495057;
  text-align: left;
}

.icon {
  color: hsl(0, 0%, 80%);
  width: 11px;
  height: 7px;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}
