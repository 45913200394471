.task-header {
  .card-body {
    padding: 24px 40px 22px 30px;
  }
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2f665c;
  
  @media only screen and (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__date {
    font-size: 16px;
    line-height: 1.5;
  }
}

.avatar-image {
  width: 72px;
  height: 72px;
  margin-right: 19px;
  @media only screen and (max-width: 425px) {
    margin-right: 0;
  }
}

.dashboard-title{
  font-size: 19px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  color:  #2f665c;
}