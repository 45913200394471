.dropzone-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px dashed #ced4da;
  padding: 0.625rem 0.125rem;
  cursor: pointer;
  height: 291px;

  div {
    outline: none;
  }

  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 290px;

    .cloud {
      font-size: 2.3rem;
    }

    &__text {
      font-size: 1rem;
      text-align: center;
      padding: 0 0.625rem;
    }
  }

  .choose-file {
    display: inline-block;
    background-color: #e1e1e1;
    font-size: 0.8125rem;
    color: #495057;
    padding: 0.1875rem 0.5rem;
    border-radius: 4px;
    border: solid 1px #e1e1e1;
    outline: none;
    cursor: pointer;
  }

  .drag-active {
    border: 2px dashed #ced4da;
    border-radius: 6px;
  }
}

.modal-buttons-wrapper {
  display: flex;
  justify-content: flex-end;

  margin-top: 32px;
  width: 100%;

  .disabled {
    opacity: 0.5;
  }
}

.modal-buttons-wrapper button {
  margin-right: 16px;
  width: 127px;
  height: 36px;
  color: #2f665c;
  padding: 9px 16px 8px;
  box-shadow: none;
  border-radius: 18px;
  border: 1px solid #2f665c;
  background-color: white;

  &:last-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 127px;
    height: 36px;
    color: white;
    padding: 8px 16px 8px;
    box-shadow: none;
    border-radius: 18px;
    background-color: #2f665c;
    margin-right: 0;
  }
}

.upload-container__text {
  width: 100%;
  max-width: 375px;
}

.upload-container__text.error-message {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #ee786c;
}
