%header-3 {
  font-size: .9375rem;
  line-height: 1.1875rem;
  font-weight: 600;
}

h1 {
  &.md-black {
    font-size: 1.1875rem;
    color: $text-dark-gomi;
    line-height: 1.4375rem;
    font-weight: 500;
  }
}

h2 {  
  &.bold-black {    
    color: $text-dark-gomi;
    font-weight: 600;
    font-size: 1rem;
  }

  &.md-white {
    color: $white;
    font-weight: 500;
    font-size: 1rem;
  }

  &.bold-green {
    color: $primary-gomi;
    font-weight: 600;
    font-size: 1rem;
  }
}

h3 {
  &.bold-black {
    @extend %header-3;
    color: $text-dark-gomi;
  }

  &.bold-red {
    @extend %header-3;
    color: $danger-gomi;
  }

  &.bold-white {
    @extend %header-3;
    color: $white;
  }
}

p, span, a, li {
  font-size: .8125rem;
  font-weight: 400;
  color: $text-dark-gomi;

  &.lg-bold-black {    
    color: $text-dark-gomi;
    font-weight: 600;
    font-size: 1rem;
  }

  &.bold-black {
    font-weight: 600;
    color: $text-dark-gomi;
  }

  &.bold-green {
    font-weight: 600;
    color: $primary-gomi;

    &:hover {
      color: $primary-gomi;
    }
  }

  &.md-black {
    font-weight: 500;
    color: $text-dark-gomi;
  }

  &.md-dark-grey {
    font-weight: 500;
    color: $text-gray-gomi;
  }

  &.md-green {
    font-weight: 500;
    color: $primary-gomi;
  }

  &.regular-black {
    font-weight: 400;
    color: $text-dark-gomi;
  }

  &.regular-green {
    font-weight: 400;
    color: $primary-gomi;
  }

  &.regular-red {
    font-weight: 400;
    color: $danger-gomi;
  }

  &.regular-white {
    font-weight: 400;
    color: $white;
  }

  &.regular-dark-grey {
    font-weight: 400;
    color: $text-gray-gomi;
  }

  &.regular-grey {
    font-weight: 400;
    color: $text-light-gray-gomi;
  }

  &.regular-orange {
    font-weight: 400;
    color: #dbaf82;
    font-size: .75rem;
  }

  &.bold-gray {
    font-size: .9375rem;
    font-weight: 600;
    color: $text-gray-gomi;
  }

  &.bold-sm-gray {
    font-weight: 600;
    color: $text-light-gray-gomi;
  }
}

.md-title-grey {
  font-size: .6875rem;
  font-weight: 500;
  color: $text-light-gray-gomi;
}

.regular-caption-gray {
  font-size: .625rem;
  color: $text-gray-gomi;
  font-weight: 400;    
}

.regular-caption-red {
  font-size: .625rem;
  color: $red;
  font-weight: 400;
}
