.dropdownButton{
  margin-bottom: 20px;
  width: 150px;
  height: 28px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #495057;
  .dropdown-menu{
    width: 150px;
  }
}
.dropdownButton:hover, .dropdownButton:focus{
  background-color: #f5f5f5;
  color: #495057;
}