.tableWrapper {
  margin-top: 20px;

  .nameWrapper {
    display: flex;
    align-items: center;

    div {
      border-radius: 50%;
      margin-right: 12px;
    }

    p {
      margin: 0;
    }
  }

  .image {
    border-radius: 50%;
    margin-right: 12px;
  }

  .title {
    font-family: Poppins;
    font-weight: 600;
    line-height: 20px;
  }

  .emailWrapper {
    display: flex;
    align-items: center;

    p {
      font-weight: normal;
      margin: 0;
    }
  }

  .statusLabel {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 93px;
    height: 20px;
    border-radius: 160px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    text-align: center;
  }

  .pendingStatusLabel {
    background-color: rgba(241, 180, 76, 0.18);
    color: #f1b44c;
  }

  .activeStatusLabel {
    background-color: #e7f6ee;
    color: #2f665c;
  }

  .failedStatusLabel {
    background-color: rgba(238, 120, 108, 0.18);
    color: #ee786c;
  }

  .actionIconButton {
    background-color: transparent;
    border: none;
  }

  .actionButton {
    padding: 5px 18px 6px;
    object-fit: contain;
    border-radius: 14px;
    margin-right: 16px;
    background: none;
    cursor: pointer;
  }

  .actionUnblockButton {
    border: 1px solid #2f665c;
    color: #2f665c;
  }

  .actionBlockButton {
    border: 1px solid #495057;
    color: #495057;
  }
}
