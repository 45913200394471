
.hidden-page .body {
    display: flex;
}

.hidden-page .list {
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-wrap: wrap;
}

.hidden-page .list p {
    font-weight: 600;
    font-size: 0.72rem;
    margin-bottom: 0 !important;
}

.hidden-page .item {
    width: 90%;
    border: 1px dashed gray;
    padding: 8px;
    background: white;
}

.hidden-page .item.draggable {
    display: inline-block;
}

.hidden-page .save {
    position: fixed;
    bottom: 8px;
    right: 8px;
}

.hidden-page .upload {
    position: fixed;
    bottom: 68px;
    right: 8px;
}


.hidden-page .search-box {
    position: sticky;
    top: 4px;
    width: calc(100% - 16px);
    z-index: 555;
    margin: 4px 0 8px 8px;

}

.hidden-page  .search-label{
    width: 100%;
}

.hidden-page  .w-320{
    width: 60%;
}


.hidden-page  .search-label input {
    box-shadow: 1px 1px 12px 2px #bfb7b7;
}

.hidden-page  a:hover {
    text-decoration: underline!important;
}

.hidden-page  button.small {
    padding: 4px;
    width: auto!important;
    min-width: 32px;
    height: auto!important;
    min-height: auto!important;
    margin-left: 8px;
    font-size: 12px!important;
    line-height: 8px;
}

.hidden-page .cross-line {
     background: whitesmoke;
 }