

.cell-market {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #2e2e2e;
  border-radius: 160px;
  background-color: #f8efe6;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  min-height: 20px;
}
