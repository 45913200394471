.buttonsWrapper {
  display: flex;
  justify-content: flex-end;

  :first-child {
    margin-right: 0.25rem;
    margin-left: 0;
  }

  :last-child {
    margin-left: 0.25rem;
    margin-right: 0;
  }
}
