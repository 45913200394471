.wrapper {
  display: flex;
  flex-direction: column;
}

.rowItemTitle {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #495057;
  margin-bottom: 8px;
}

@media (max-width: 767px) {
  .rowItemTitle {
    margin: 0;
  }
}
