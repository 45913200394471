.task {
  &-checkbox {
    width: 16px;
    height: 16px;
  }

  &-from{
    color: #2f665c;
  }

  &-description {
    color: #74818d;
    border-bottom: 1px solid #f6f6f6;
  }
}

.form-check-input:checked {
  background-color: #2f665c;
  border-color: #2f665c;
}

.comments-container{
  svg{
    fill: #495057;
  }
}
