.tableWrapper {
}

.tableTitleWrapper {
  display: flex;
  align-items: center;

  div,
  img {
    margin-right: 12px;
  }

  p {
    margin: 0;
  }
}

.title {
  font-family: Poppins;
  font-weight: 600;
  line-height: 20px;
}

.tableActionsWrapper {
  button {
    margin-left: 16px;
  }
}

.actionIconButton {
  border: none;
  background: none;
}
