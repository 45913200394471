.wrapper {
  display: flex;
  width: 100%;
}

.imageWrapper {
  min-width: 240px;
  margin-right: 32px;
}

.image {
  display: block;
  width: 240px;
  height: 240px;
  border-radius: 4px;
  object-fit: contain;
}

.mainContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 765px;
  width: 100%;
}

.title {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.27;
  color: #495057;
  margin-bottom: 12px;
}

.sybTitle {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: #495057;
  margin-bottom: 24px;
}

.description {
  font-family: Poppins;
  font-size: 13px;
  color: #74818d;
  margin-bottom: 32px;
}

.tableWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 580px;
  width: 100%;
  margin-bottom: 65px;
}

.tableDetailWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 580px;
  width: 100%;
  margin-bottom: 65px;
}

.editBtn {
  min-width: 108px !important;
  margin-left: auto;
  margin-top: 35px;
}

.mainPriceInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 8px;
}

.saveBtnWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
}

.warning {
  font-family: Poppins;
  font-size: 13px;
  color: #495057;
}

.saveBtn {
  min-width: 108px !important;
}

.dropdownWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 365px;
  margin-bottom: 16px;

  label {
    margin-bottom: 0;
  }

  & > div {
    width: 220px;
  }
}
