.button {
  margin-left: 40px;
  min-width: 118px;
  font-size: 16px;
}

.text {
  color: #2e2e2e;
}

.icon {
  margin-right: auto;
}