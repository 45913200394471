
.chartWrap {
  position: relative;
  height: 480px;
  width: 480px;
}

.chartInner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chartInnerLabel{
  text-align: center;
}

.chartTotal{
  font-family: Poppins, serif;
  font-size: 19px;
  font-weight: 600;
  color: #2f665c;
  margin: 0 auto;
  text-align: center;
}

.chart{
  margin-top: -48px;
  z-index: 0;
}



// Next one - Oval
.ovalAnalytics {
  position: absolute;
  padding-top: 30px;
  left: -100px;


  svg {
    width: 100%;
    height: auto;
  }

  .inner {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
  }

  .ovalProgress {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: transform 0.2s ease-in-out;
    transform-origin: bottom;
    color: #2f665c;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      bottom: -9px;
      right: -27px;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 18px solid #59857d;
      transform: rotate(
                      90deg);
    }

  }
  .ovalProgressAbove{
    position: absolute;
    bottom: -161px;
    left: -1px;
    background-color: #ffffff;
    height: 163px;
    width: 102%;
  }
  .ovalBg {
    margin-top: auto;
    color: #F5F5F5;
  }
}

@media (min-width: 753px) and (max-width: 902px) {
  .ovalAnalytics {

    position: absolute;
    left: -100px;
    top: 70px;
    width: 320px;

  }
}

@media (min-width: 978px) and (max-width: 1350px) {
  .ovalAnalytics {
    position: absolute;
    left: -100px;
    top: 60px;
    width: 320px;
    .ovalProgressAbove{
      position: absolute;
      bottom: -161px;
      left: -1px;
      background-color: #ffffff;
      height: 163px;
      width: 102%;
    }
  }
}

@media (min-width: 978px) and (max-width: 1060px) {
  .ovalAnalytics {
    position: absolute;
    left: -92px;
    top: 80px;
    width: 296px;
  }
}



.ovalDashboard{
  position: relative;
  padding-top: 30px;
  width: 94%;
  max-width: 540px;
  max-height: 100%;
  margin: 0 auto;


  svg {
    width: 100%;
    height: auto;
  }

  .inner {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
  }

  .ovalProgress {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: transform 0.2s ease-in-out;
    transform-origin: bottom;
    color: #2f665c;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      bottom: -9px;
      right: -27px;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 18px solid #59857d;
      transform: rotate(
                      90deg);
    }
  }
  .ovalProgressAbove{
    position: absolute;
    bottom: -161px;
    left: 0;
    background-color: #ffffff;
    height: 163px;
    width: 100%;
  }

  .ovalBg {
    margin-top: auto;
    color: #F5F5F5;
  }
}

@media (min-width: 1024px) and (max-width: 1070px) {
  .ovalDashboard {
    top: 60px;
  }
}

