.data-list-container{
  position: relative;
  display: flex;
  align-items: center;
}

.info-icon{
  position: absolute;
  top: 5px;
  left: -28px;

}

.date-list-tooltip{
}

.tooltip{
  top: -104px;
}

.tooltip-inner{
  max-width: 432px;
}

.sort-inventory-button{
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}