
body .date-filter{
  width: 100%;
}

body .date-filter .react-datepicker-wrapper input {
  width: 100%;
  height: 36px;
  display: block;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  &::placeholder{
    color: #a1aab3;
  }
}

.expense-form  .react-datepicker__day--disabled{
  color: grey !important;
}

.react-datepicker__day--selected{
  border-radius: 13.5px;
  background-color: #2f665c !important;
  opacity: 0.5;
  color: #ffffff !important;
}


