.headerContainer{
  margin-top: 74px;
}

.calendarContainer{
  display: flex;
  align-items: center;
  height: 34px;
}

.calendarData{
  margin: 3px 8px 0 0;
  font-size: 15px;
  line-height: 1;
  color: #2f665c;
}