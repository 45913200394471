//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }

  .table-light {
    --bs-table-bg: $table-head-bg;

    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: white;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.rounded-header tr{
  th:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  th:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}