.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.title {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  color: #495057;
  margin-right: 8px;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .wrapper {
    display: none;
  }
}
