.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 365px;
  margin-bottom: 16px;

  p {
    margin-bottom: 0;
  }
}

.wrapperInput {
  width: 220px;

  div {
    width: 34px;
  }
}

.errorWrapper {
  input {
    border-color: #f46a6a !important;
  }
}

.columnView {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .title {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  & > div {
    display: flex;
    width: 100%;
  }
}

.title {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  color: #495057;
  width: 114px;
}

.priceError {
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 80%;
  color: #f46a6a;
}
