.action-icon-button {
  background-color: transparent;
  border: none;

  &__without-actions {
    margin-right: 4.45rem;

    @media screen and (max-width: 519px) {
      margin-right: 0;
    }
  }

  &__text {
    color: #495057;
    font-weight: 500;
  }

  &.is-active {

    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    transition: all .3s ease;
    fill: #495057;
  }
}
