.discountedShipping {
  div[class*="-singleValue"] {
    .discount-footer {
      display: none;
    }
  }
  .isDisabled {
    pointer-events: none;
    cursor: none;
    p {
      color: #74818d;
    }
  }
}
