@mixin tableCellStyle() {
  color: #343A40;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.table-sort-icon {
  margin-left: 6px;
}

.date-sort-icon{
  margin-top: 6px;
}

.drafts-page {

  .highlighted-text {
    font-weight: inherit;
    background-color: rgba(47,102,92,0.2);
  }

  .drafts-table {
    @include tableCellStyle();
    min-height: 310px;
  }

  .draft-header-counters {
    display: flex;
    align-items: center;
    margin-left: auto;

    &__add {
      margin-left: 26px;
    }
  }

  .inventory-header-counters {
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-direction: row;

    @media (max-width: 1418px){
      width: 100%;
      margin: 0 auto;
      justify-content: flex-end;
    }

    &__add {
      margin-left: 26px;
    }
  }

  .sold-header-counters {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media (max-width: 1300px){
      width: 680px;
      margin: 0 auto;
      justify-content: space-between;
    }

    &__add {
      margin-left: 26px;
    }
  }

  .drafts-table-cell-title {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      @include tableCellStyle();
      margin-left: 16px;
      margin-bottom: 0;
      overflow: hidden;
      max-width: 16vw;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  p {
    margin-bottom: 0;
  }

  .table-cell-date {
    font-weight: normal;
  }

  .drafts-table-cell-date {
    font-weight: normal;
  }

  .table-actions {

    button {
      margin-left: 16px;
    }

  }

  .icon-button {
    background-color: transparent;
    border: none;
  }
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.cell-bold {
  font-weight: bold;
}

.cell-semibold {
  font-weight: 600;
}

