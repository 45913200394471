.step-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  margin-left: 8px;

  .step-form-container {
    display: flex;
    position: relative;
    margin-bottom: auto;

    .steps-navigation {
      display: block;
      width: 20%;

      margin-right: 30px;
    }

    .step-form-content {
      display: block;
      width: 80%;

      .onboarding-instruction {
        margin-right: 75px;
      }
    }

    .vertical-divider {
      border-left: 1px solid #ced4da;
      height: 438px;
      position: absolute;
      left: 185px;
      top: 0;
    }

    .avatar-step {
      display: flex;
    }

    .avatar-step button {
      width: 122px;
      height: 27px;
      margin: 23px 0 22px 24px;
      padding: 5px 24px 6px 23px;
      object-fit: contain;
      border: 1px solid #2f665c;
      border-radius: 13.5px;
      background-color: #2f665c;
      box-shadow: none;
      font-family: Poppins;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }

  .step-worm-buttons-wrapper {
    display: flex;
    margin-left: auto;
    margin-right: 8px;
    margin-bottom: 8px;
  }

}

.ui-black-bold {
  font-size: .9375rem;
  font-weight: 600;
  line-height: 1.27;
  color: #343a40;
}

.video-container {
  margin-bottom: 1.25rem;
}