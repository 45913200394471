.wrapper {
  margin-top: 20px;
  max-width: 444px;
}

.editAvatarWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto 40px 10px;
}

.avatarWrapper {
  display: flex;
  margin-right: 24px;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  z-index: 3;
}

.changeAvatarButtonsWrapper {
  display: flex;

  button {
    &:first-of-type {
      margin-right: 8px;
    }
  }
}

.inputWrapper {
  margin: 0 4px 24px 4px;
}

.formButtonWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 48px;

  button {
    &:first-of-type {
      margin-right: 8px;
    }
  }
}
