.dropdownContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 121px;
  height: 36px;
  border-radius: 20px;
  border: solid 1px #2f665c;
  vertical-align: center;
  padding: 8px 20px;
  font-size: 13px;
  font-weight: normal;
  color: #2f665c;
  background: transparent;

  &:active, &:hover, &:focus{
    border-radius: 20px;
    border: solid 1px #2f665c;
    vertical-align: center;
    padding: 8px 20px;
    font-size: 13px;
    font-weight: normal;
    color: #2f665c;
    background: transparent;
  }
  i{
    font-size: 16px;
    margin-right: 2px;
  }

}

