.inventoryCard{
 padding: 15px 30px 24px;
  th:first-child{
    padding-left: 19px;
  }

  th:nth-child(n+2){
    text-align: center;
  }

  th:last-child{
    text-align: right;
    padding-right: 18px;
  }

  td:nth-child(n+2){
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  td{
    height: 82px;
    &:last-child{
      padding-right: 67px;
      div{
        justify-content: flex-end;
      }
    }
    &:first-child{
      padding-left: 19px;
    }
  }
}

.headerContainer{
  display: flex;
  align-items: center;
}

.headerTitle{
  display: flex;
  align-items: center;
  font-family: Poppins, serif;
  font-size: 19px;
  margin-bottom: 0;
  font-weight: 600;
  color: #2f665c;
  margin-right: 24px;
}

.headerBrandButton{
  width: 153px;
  height: 35px;
  background-color: #eff6f2;
  color: #2f665c;
  font-family: "Poppins", serif;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2px;
  padding-left: 16px;
  border-radius: 3.2px;
  &:focus, &:hover {
    color: #2f665c;
  }

  i{
    width: 24px;
    height: 24px;
  }
}

.headerCountButton {
  width: 94px;
  height: 28px;
  background-color: #f5f5f5;
  color: #495057;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 16px;
  font-family: "Poppins", serif;
  font-size: 12px;
  font-weight: normal;
  i{
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  &:hover, &:focus{
    background-color: #f5f5f5;
    color: #495057;
  }
}

.inventoryTitleContainer{
  display: flex;
  align-items: center;
}
.inventoryTitleIndex{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: #eff6f2;
  color: #2f665c;
  font-family: 'Poppins', serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 50%;
  margin-right: 16px;
}
.inventoryTitleName{
  font-family: Poppins, serif;
  font-size: 16px;
  font-weight: 600;
  color: #495057;
  overflow: hidden;
}

.tableText{
  font-family: Poppins, serif;
  font-size: 15px;
  font-weight: normal;
}