.wrapper {
  display: flex;
  align-items: flex-end;
}

.dateWrapper {
  display: flex;
  align-items: center;
}

.dateSeparator {
  font-family: Poppins;
  font-size: 13px;
  text-align: center;
  color: #74818d;
  margin: 0 12px;
}
