.showMoreAdditionsFieldsBtn {
  border: none;
  background-color: none;
  flex-grow: 0;
  padding-top: 0;
  padding-left: 0;
  margin-bottom: 1.5rem;

  & > div {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #2f665c;
  }
}

.flippedIcon {
  transform: rotateX(180deg);
}
