.inventory-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30vh;
  margin-bottom: 30vh;

  &__text {
    text-align: center;
    max-width: 302px;
    margin-top: 1rem;
    color: #74818d;
    font-size: 15px;
    font-weight: 600;
  }
}
