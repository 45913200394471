.system-block {
  width: 65px;
  height: 35px;
  border-radius: 3.2px;
  background-color: #f5f5f5;

  collapse.show {
    position: relative;
  }

  .menu-system{
    width: 213px;
    height: 164px;
    margin: 6px 18px 1px 20px;
    padding: 20px 1px 20px 20px;
    object-fit: contain;
    border-radius: 4px;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.15);
    background-color: #fff;

    position: absolute;
    right: 0;
    z-index: 1;

    .system-flag {
      object-fit: cover;
      width: 33px;
      height: 22px;
      margin-right: 20px;
    }
  }
}